/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Intro Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Intro = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none">
                <path
                    d="M200.5 247.7h-5.13V246h5.13v1.7zm-10.27 0h-.66c-1.65 0-3.26-.36-4.73-1.04l.73-1.55c1.24.58 2.6.88 4 .88h.66v1.72zm-9.68-4.57a11.12 11.12 0 01-2.06-5.16l1.7-.22c.2 1.59.8 3.09 1.74 4.37l-1.38 1.01zm-2.78-10a9.49 9.49 0 00-2.73-3.83l1.1-1.3a11.2 11.2 0 013.23 4.52l-1.6.62zm-6.9-5.87a9.5 9.5 0 00-1.9-.2h-3.09v-1.7h3.08c.77 0 1.52.07 2.25.23l-.35 1.67zm-10.33-.86a15.07 15.07 0 01-4.82-2.52l1.05-1.35c1.28 1 2.72 1.75 4.28 2.23l-.5 1.64zm-8.46-6.6a15.02 15.02 0 01-1.94-5.08l1.68-.31c.3 1.6.88 3.12 1.72 4.5l-1.46.9zm-2.2-10.38v-5.13h1.72v5.13h-1.71zm0-10.26v-5.14h1.72v5.14h-1.71zm0-10.27v-5.13h1.72v5.13h-1.71zm0-10.27v-5.13h1.72v5.13h-1.71zm0-10.26v-.38c0-1.37-.65-2.64-1.73-3.44l1.01-1.38a5.98 5.98 0 012.44 4.82v.38h-1.71zm-6.3-4.65h-5.14v-1.72h5.13v1.72zM133.5 148.7h5.13V147h-5.13v1.7zm10.27 0h3.88c.44 0 .89-.01 1.33-.05l-.16-1.7a13 13 0 01-1.17.04h-3.88v1.72zm10.48-1.57a14.6 14.6 0 004.3-3.35l-1.28-1.13a12.88 12.88 0 01-3.8 2.95l.78 1.53zm7.06-7.98c.58-1.57.88-3.23.88-4.94v-.47h-1.7l-.01.47c0 1.5-.27 2.97-.77 4.35l1.6.59zm.88-10.54v-5.13h-1.7v5.13h1.7zm0-10.27v-5.13h-1.7v5.13h1.7zm0-10.25c.02-1.63.4-3.2 1.08-4.64l-1.55-.74a12.78 12.78 0 00-1.24 5.36l1.71.02zm4-8.41a11.09 11.09 0 014.23-2.2l-.44-1.65a12.8 12.8 0 00-4.88 2.54l1.1 1.3zm9.1-2.58h5.14v-1.7h-5.13v1.7zm10.27 0h5.14v-1.7h-5.14v1.7zm10.5-.83c1.74-.66 3.3-1.7 4.6-3.02l-1.22-1.2a11.13 11.13 0 01-3.99 2.62l.61 1.6zm7.5-7.63c.5-1.38.76-2.83.77-4.32v-1.09h-1.71v1.08c0 1.3-.23 2.56-.66 3.75l1.6.58zm.77-10.54v-5.13h-1.71v5.13h1.7zm0-10.27V62.7h-1.71v5.13h1.7zm0-10.26v-5.14h-1.71v5.14h1.7zm0-10.27v-5.13h-1.71v5.13h1.7zm.12-10.13c.24-1.6.82-3.12 1.7-4.45l-1.42-.94a12.76 12.76 0 00-1.97 5.13l1.69.26zm5.16-7.8a11.05 11.05 0 014.5-1.58l-.21-1.7c-1.86.22-3.62.85-5.2 1.82l.9 1.45zm9.47-1.66h5.13v-1.72h-5.13v1.72zm10.26 0h5.14v-1.72h-5.14v1.72zm10.27 0h5.13v-1.72h-5.13v1.72z"
                    fill="#C8C8D0"
                />
                <path
                    d="M7.97 202.1h83.31V82.47H7.97z"
                    fill="#D56B50"
                />
                <path
                    d="M86.91 76.02H1.6v121.66H86.9V76.02zm-2.01 2.04v117.58H3.6V78.06h81.3zM101.02 66.23v104.68h-2.01V66.23zM113.12 117.96v69.26h-2.02v-69.26z"
                    fill="#2E3542"
                />
                <path
                    d="M21.4 165.4h51.07v-8.16H21.4zM18.72 184.43h53.75v-8.16H18.72zM39.03 135.54a4.12 4.12 0 011.13-2.86 3.86 3.86 0 012.83-1.19 3.9 3.9 0 012.85 1.19 4.07 4.07 0 01-1.28 6.58c-.49.2-1.01.3-1.57.3a4.03 4.03 0 01-2.83-1.15 4.07 4.07 0 01-1.13-2.87zm-5.53-35.2a17.96 17.96 0 014.61-3 15.14 15.14 0 016.33-1.27c1.63 0 3.13.23 4.5.69 1.36.46 2.54 1.12 3.53 1.97 1 .85 1.77 1.88 2.32 3.09.55 1.2.82 2.55.82 4.04 0 1.49-.21 2.78-.64 3.87a10.44 10.44 0 01-1.66 2.83c-.66.8-1.4 1.5-2.18 2.1l-2.22 1.68c-.7.52-1.3 1.04-1.8 1.56s-.8 1.1-.9 1.72l-.62 4.41h-4.3l-.45-4.85c-.1-.92.05-1.72.44-2.38.4-.67.92-1.3 1.58-1.88.66-.6 1.39-1.16 2.18-1.7.8-.53 1.54-1.13 2.23-1.8a10 10 0 001.74-2.24 5.87 5.87 0 00.26-5.04c-.29-.62-.7-1.14-1.23-1.57a5.82 5.82 0 00-1.86-1 9.2 9.2 0 00-5.25.06 12.25 12.25 0 00-3.48 1.78c-.37.26-.69.4-.94.4-.61 0-1.06-.27-1.36-.8l-1.65-2.68z"
                    fill="#FAF6F4"
                />
                <g>
                    <path
                        d="M351.03 195.3s-41.66-42.57-41.66-69.4c0-23.23 18.65-42.07 41.66-42.07 23 0 41.66 18.84 41.66 42.07 0 26.83-41.66 69.4-41.66 69.4z"
                        fill="#D56B50"
                    />
                    <path
                        d="M341.62 80.1c-23.56 0-42.66 19.28-42.66 43.08 0 6.95 2.67 15.2 7.59 24.5a163.9 163.9 0 0013.44 20.88c4.58 6.15 9.48 12.1 14.37 17.63l1.27 1.42.61.68 1.19 1.3 1.13 1.23 1.64 1.74.7.74.72.73 1.2-1.22 1.33-1.4 1.1-1.2 1.16-1.26.6-.66 1.24-1.38a284.41 284.41 0 0015-18.35 163.9 163.9 0 0013.45-20.88c4.92-9.3 7.59-17.55 7.59-24.5 0-23.8-19.1-43.08-42.67-43.08zm0 2.04c22.45 0 40.65 18.37 40.65 41.04 0 6.55-2.57 14.51-7.34 23.54a161.92 161.92 0 01-13.28 20.61c-4.54 6.1-9.4 12.01-14.27 17.5l-1.25 1.4-1.2 1.34-.59.64-1.12 1.21-1.1 1.19-.5.51-1.05-1.12-1.1-1.17-.57-.62-1.17-1.3-1.23-1.37a282.45 282.45 0 01-14.9-18.2 161.92 161.92 0 01-13.28-20.62c-4.77-9.03-7.34-16.99-7.34-23.54 0-22.67 18.2-41.04 40.64-41.04z"
                        fill="#2E3542"
                    />
                    <path
                        d="M363.12 126.65a18.25 18.25 0 01-18.14 18.36 18.25 18.25 0 01-18.14-18.36 18.25 18.25 0 0118.14-18.35 18.25 18.25 0 0118.14 18.35"
                        fill="#FAF6F4"
                    />
                </g>
                <g>
                    <path
                        d="M239.3 268.13s-18.84-19.09-18.84-31.12a18.86 18.86 0 1137.7 0c0 12.03-18.85 31.12-18.85 31.12z"
                        fill="#E9E9EC"
                    />
                    <path
                        d="M244.58 237.08c0 4.6-3.7 8.33-8.29 8.33a8.31 8.31 0 01-8.3-8.33c0-4.6 3.72-8.33 8.3-8.33s8.3 3.73 8.3 8.33"
                        fill="#C8C8D0"
                    />
                    <path
                        d="M234.78 215.78a19.71 19.71 0 00-19.7 19.72c0 4.53 2.44 10.13 6.65 16.44l.62.92a120.08 120.08 0 008.52 10.79l.79.88.75.82 1.03 1.1.73.77.61.62.97-.98.46-.5.58-.61.6-.66.65-.7a128.06 128.06 0 006.85-8.3 73.8 73.8 0 006.12-9.42c2.25-4.22 3.48-7.98 3.48-11.17a19.71 19.71 0 00-19.7-19.72zm0 1.71a18 18 0 0118 18c0 2.87-1.15 6.38-3.28 10.38-1.55 2.9-3.58 6-5.98 9.19a119.76 119.76 0 01-6.43 7.82l-.64.71-.32.35-.88.95-.47.5-.41-.44-.7-.76-.37-.4-.76-.83a127.44 127.44 0 01-6.78-8.28c-5.62-7.56-8.97-14.25-8.97-19.18a18 18 0 0118-18.01z"
                        fill="#2E3542"
                    />
                </g>
                <g>
                    <path
                        d="M278.54 43.8s-15.07-15.32-15.07-24.97a15.1 15.1 0 0115.07-15.14 15.1 15.1 0 0115.08 15.14c0 9.65-15.08 24.97-15.08 24.97z"
                        fill="#E9E9EC"
                    />
                    <path
                        d="M282.77 18.88c0 3.7-2.97 6.69-6.64 6.69-3.66 0-6.63-3-6.63-6.69 0-3.69 2.97-6.68 6.63-6.68 3.67 0 6.64 3 6.64 6.68"
                        fill="#C8C8D0"
                    />
                    <path
                        d="M274.93 1.8c-8.71 0-15.77 7.08-15.77 15.81 0 4.3 2.74 9.82 7.33 16.05a95.65 95.65 0 005.16 6.38l.39.43.74.83 1.02 1.1.64.66.48.5.54-.54.6-.63.65-.7.72-.8a102.24 102.24 0 005.93-7.23l.65-.89c4.2-5.86 6.68-11.06 6.68-15.16A15.8 15.8 0 00274.93 1.8zm0 1.36c7.95 0 14.4 6.47 14.4 14.45 0 3.92-2.64 9.23-7.07 15.23a94.3 94.3 0 01-5.1 6.3l-.74.84-.36.4-1 1.07-.13.14-.44-.46-.7-.75-.73-.82a102.37 102.37 0 01-5.47-6.72c-4.43-6-7.06-11.3-7.06-15.23 0-7.98 6.45-14.45 14.4-14.45z"
                        fill="#2E3542"
                    />
                </g>
                <path
                    d="M149.95 153c6.17 0 11.2 4.92 11.35 11.06v.29c0 5.23 4.17 9.5 9.37 9.64h49.75c5.23 0 9.5-4.17 9.64-9.37v-.27c0-6.17 4.93-11.2 11.06-11.35H283.5v1.7h-42.08a9.64 9.64 0 00-9.65 9.38v.27c0 6.17-4.93 11.2-11.06 11.35h-49.76c-6.18 0-11.2-4.92-11.36-11.06v-.29c0-5.23-4.17-9.5-9.37-9.64H133.5v-1.72h16.45z"
                    fill="#2E3542"
                />
            </g>
        </svg>
    );
});

Intro.displayName = 'Intro';
Intro.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Intro.defaultProps = {
    className: '',
    height: 269,
    width: 394
};