/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Divider Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Divider = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M11 7c-2.21 0-3.7-.7-6.03-2.47l-.88-.68C2.42 2.58 1.4 2.05.19 2H0V0c1.69 0 2.96.55 4.73 1.83l.94.71.21.17C8.08 4.4 9.26 5 11 5c1.66 0 2.77-.53 4.78-2.08l.62-.49C18.63.7 20 0 22 0c1.84 0 3.1.54 5.1 1.98l.55.41.41.32.53.39.48.35C30.69 4.61 31.67 5 33 5c1.34 0 2.33-.4 3.96-1.55l.48-.35.73-.55C40.6.7 41.97 0 44 0c1.85 0 3.13.55 5.25 2.06l.57.41.4.3.52.38.48.34C52.82 4.61 53.78 5 55 5c1.26 0 2.24-.46 3.84-1.66l.81-.63C62.05.85 63.54.07 65.71.01L66 0v2c-1.61 0-2.74.5-4.58 1.87l-1.1.86C58.23 6.33 56.87 7 55 7c-1.87 0-3.17-.6-5.37-2.18l-1.24-.91-.5-.36C46.25 2.4 45.28 2 44 2c-1.33 0-2.33.46-4.1 1.75l-1.3.98C36.34 6.38 35 7 33 7c-1.92 0-3.22-.56-5.3-2.07l-.56-.41-.67-.52-.5-.36C24.29 2.42 23.32 2 22 2c-1.37 0-2.37.48-4.11 1.81l-.9.7-.57.43C14.4 6.42 13.03 7 11 7z"
                fill={color}
            />
        </svg>
    );
});

Divider.displayName = 'Divider';
Divider.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Divider.defaultProps = {
    className: '',
    color: '#D56B50',
    height: 7,
    width: 66
};