/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * BadgeBackground Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const BadgeBackground = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none">
                <path
                    d="M76.81 24.35A39.94 39.94 0 0024.4 3.17 40.06 40.06 0 003.2 55.65 39.94 39.94 0 0055.6 76.83a40.06 40.06 0 0021.2-52.48z"
                    stroke="#000"
                    strokeDasharray="33.81785310685446,90.49848526332912"
                />
                <path
                    d="M20.57 9.24C37.54-.28 58.54 3 69.63 17.72c11.1 14.73 8.46 35.82-5.36 49.5z"
                    fill="#B5C2B8"
                />
                <g>
                    <path
                        d="M78.2 26.6l-.95.34A39.45 39.45 0 0054.81 3.48l.38-.93a40.45 40.45 0 0123 24.06zM2.3 54.74l.92-.36a39.43 39.43 0 0023.12 22.8l-.35.93-1.1-.43A40.43 40.43 0 012.3 54.73zM55.17 2.55l-.38.93c-.71-.3-1.43-.56-2.15-.8l.32-.95c.74.25 1.48.52 2.2.82z"
                        fill="#000"
                        opacity=".55"
                    />
                    <path
                        d="M20.57 9.24C37.54-.28 58.54 3 69.63 17.72c11.1 14.73 8.46 35.82-5.36 49.5z"
                        fill="#B5C2B8"
                    />
                </g>
            </g>
        </svg>
    );
});

BadgeBackground.displayName = 'BadgeBackground';
BadgeBackground.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
BadgeBackground.defaultProps = {
    className: '',
    height: 80,
    width: 80
};