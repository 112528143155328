/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Clock Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Clock = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M12 0a12.01 12.01 0 0 1 0 24 12.01 12.01 0 0 1 0-24zm0 2a10 10 0 0 0 0 20 10 10 0 0 0 0-20zm1.12 2v7.73l2.88 2.8L14.5 16 11 12.58V4h2.12z" fill={color} />
        </svg>
    );
});

Clock.displayName = 'Clock';
Clock.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Clock.defaultProps = {
    className: '',
    color: '#2E3542',
    height: 24,
    width: 24
};