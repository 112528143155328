/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Location Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Location = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fillRule="evenodd">
                <path d="M39 85S8 53.7 8 33.9a31 31 0 0 1 62 0C70 53.7 39 85 39 85z" fill="#d56b50" />
                <path d="M32 .2A31.8 31.8 0 0 0 .2 31.9c0 5.2 2 11.2 5.7 18.1 2.6 4.9 6 10 10 15.3 3.4 4.5 7.1 8.9 10.7 13l.9 1c.2.2.3.4.5.5l.9 1 .8.9 1.2 1.3.6.5.5.6.9-.9 1-1.1.8-.8.9-1c.1-.1.3-.3.4-.5l.9-1c.2-.1.3-.3.5-.5 3.6-4.1 7.3-8.5 10.7-13 4-5.3 7.4-10.4 10-15.3 3.7-6.9 5.7-12.9 5.7-18.1A31.8 31.8 0 0 0 32 .2zm0 1.6c16.7 0 30.3 13.5 30.3 30.1 0 4.9-2 10.7-5.5 17.4-2.6 4.8-5.9 9.9-9.9 15.1-3.4 4.5-7 8.9-10.6 12.9l-.9 1-.9 1-.5.5-.8.9-.8.8-.4.4-.8-.8-.8-.9-.4-.4-.9-1-.9-1-.5-.5c-3.6-4-7.2-8.4-10.6-12.9-4-5.2-7.3-10.3-9.9-15.1-3.5-6.7-5.4-12.5-5.4-17.4C1.8 15.3 15.3 1.8 32 1.8z" fill="#2e3542" fillRule="nonzero" />
                <path d="M48 34.5C48 42 42 48 34.5 48S21 42 21 34.5 27 21 34.5 21 48 27 48 34.5" fill="#faf6f4" />
            </g>
        </svg>
    );
});

Location.displayName = 'Location';
Location.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Location.defaultProps = {
    className: '',
    height: 88,
    width: 72
};