/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Search Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Search = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M15.01 15.8a7.16 7.16 0 0 1-9.91 2.17A7.1 7.1 0 0 1 2.93 8.1a7.15 7.15 0 0 1 9.91-2.17 7.1 7.1 0 0 1 3.14 4.5 7.09 7.09 0 0 1-.97 5.39zM24 20.5l-7.07-4.53A8.9 8.9 0 0 0 8.98 3a8.93 8.93 0 1 0-.02 17.9c2.67 0 5.29-1.2 7.03-3.4l7.04 4.5.97-1.5z" fill={color} />
        </svg>
    );
});

Search.displayName = 'Search';
Search.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Search.defaultProps = {
    className: '',
    color: '#2E3542',
    height: 24,
    width: 24
};