/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Male Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Male = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M11.67 17a4.67 4.67 0 110-9.34 4.67 4.67 0 010 9.34zm3.18-12v.94h3.48l-2.55 2.53A5.68 5.68 0 006 12.37C6 15.46 8.55 18 11.68 18a5.67 5.67 0 005.69-5.64c0-1.17-.37-2.26-.99-3.16l2.67-2.64v3.55H20V5h-5.15z"
                fill={color}
            />
        </svg>
    );
});

Male.displayName = 'Male';
Male.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Male.defaultProps = {
    className: '',
    color: '#2e3542',
    height: 24,
    width: 24
};