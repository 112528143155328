/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Quotation Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Quotation = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M1.51 21.84a9.2 9.2 0 0 0 5.98-4.58c1.34-2.38 2.01-5.6 2.01-9.69V.84h-9v9h3.85v.94c0 2.18-.24 3.95-.7 5.29a4.8 4.8 0 0 1-2.14 2.77v3zm15 0a9.2 9.2 0 0 0 5.98-4.58c1.34-2.38 2.01-5.6 2.01-9.69V.84h-9v9h3.85v.94c0 2.18-.24 3.95-.7 5.29a4.8 4.8 0 0 1-2.14 2.77v3z" fill="#FFF" />
        </svg>
    );
});

Quotation.displayName = 'Quotation';
Quotation.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Quotation.defaultProps = {
    className: '',
    height: 22,
    width: 25
};