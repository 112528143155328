/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Logo Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Logo = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M6.3 23v13.3h16.66V23h5.98v32.2h-5.98V41.4H6.3v13.8H.32V23H6.3zm48.3 0l14.54 32.2H62.9l-3.22-7.45h-16.1l-3.18 7.45h-6.16L48.72 23h5.89zm36.49 0l14.54 32.2h-6.26l-3.22-7.45h-16.1l-3.17 7.45H70.7L85.21 23h5.88zm33.08 0c8.46 0 13.7 4.32 13.7 11.45 0 4.88-2.48 8.47-6.85 10.17l7.4 10.58H132l-6.58-9.43c-.41.05-.83.05-1.24.05h-7.27v9.38h-5.98V23zm-72.55 5.98l-6.03 14.08h12.1l-6.07-14.08zm36.48 0l-6.03 14.08h12.1L88.1 28.98zm35.8-.92h-7v12.83h7c5.24 0 7.95-2.39 7.95-6.44 0-4.04-2.71-6.39-7.96-6.39zM105.81.64c1.34 0 2.75.35 3.72 1l-.65 1.6c-1.01-.58-2.11-.87-3.09-.87-1.73 0-2.43.67-2.43 1.52 0 2.38 6.64.83 6.64 4.94 0 1.8-1.46 3.33-4.53 3.33-1.74 0-3.49-.54-4.42-1.34l.72-1.62a6.2 6.2 0 003.7 1.23c1.74 0 2.48-.62 2.48-1.45 0-2.42-6.66-.84-6.66-5 0-1.8 1.44-3.34 4.52-3.34zM79.32.8v6.24c0 2.3 1.03 3.3 2.84 3.3 1.81 0 2.83-1 2.83-3.3V.8h2.05v6.32c0 3.26-1.84 5.04-4.9 5.04-3.07 0-4.9-1.78-4.9-5.04V.8h2.07zM2.21.8l4.44 7.42L11.03.8h1.71l.02 11.2h-1.97l-.01-7.42-3.68 6.14h-.93L2.49 4.67V12H.5V.8h1.72zm22.4 0v1.74h-6.09v2.92h5.4v1.7h-5.4v3.1h6.32V12h-8.4V.8h8.18zm5.3 0V12h-2.08V.8h2.08zm5.4 0l6.18 7.58V.8h2.06V12h-1.71l-6.18-7.58V12h-2.06V.8h1.71zm18.7 0l6.19 7.58V.8h2.06V12h-1.71l-6.18-7.58V12h-2.06V.8h1.7zm20.1 0v1.74h-6.1v2.92h5.41v1.7h-5.4v3.1h6.31V12h-8.4V.8h8.18zM98.8.8v1.74h-6.1v2.92h5.4v1.7h-5.4v3.1h6.32V12h-8.4V.8h8.18z"
                fill={color}
            />
        </svg>
    );
});

Logo.displayName = 'Logo';
Logo.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Logo.defaultProps = {
    className: '',
    color: '#000',
    height: 56,
    width: 139
};