/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * CheckBold Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const CheckBold = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox="0 0 24 20"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="m1.667 10.581 5.752 5.752 15-15" fill="none" fillRule="evenodd" stroke={color} strokeWidth="4" />
        </svg>
    );
});

CheckBold.displayName = 'CheckBold';
CheckBold.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
CheckBold.defaultProps = {
    className: '',
    color: '#77C187',
    height: 20,
    width: 24
};