/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Close Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Close = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M10.38 1L6 5.38 1.63 1 1 1.62 5.38 6 1 10.38l.63.62L6 6.62 10.38 11l.62-.62L6.62 6 11 1.62z"
                fill={color}
            />
        </svg>
    );
});

Close.displayName = 'Close';
Close.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Close.defaultProps = {
    className: '',
    color: '#2e3542',
    height: 12,
    width: 12
};