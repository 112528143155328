/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Female Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Female = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M7.92 8.7a4.67 4.67 0 014.58-4.75 4.67 4.67 0 014.58 4.74 4.67 4.67 0 01-4.58 4.75 4.67 4.67 0 01-4.58-4.75M18 8.7A5.6 5.6 0 0012.5 3 5.6 5.6 0 007 8.7a5.62 5.62 0 005.04 5.66v3.25H8.72v.95h3.32V22h.92v-3.44h3.32v-.95h-3.32v-3.25A5.62 5.62 0 0018 8.7"
                fill={color}
            />
        </svg>
    );
});

Female.displayName = 'Female';
Female.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Female.defaultProps = {
    className: '',
    color: '#2e3542',
    height: 24,
    width: 24
};