/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Content Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Content = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color}>
                <path d="M6 20h18H6zM6 21h18v-2H6zM6 13h18H6zM6 14h18v-2H6zM6 5h18H6zM6 6h18V4H6zM0 20h2-2zM0 21h2v-2H0zM0 13h2-2zM0 14h2v-2H0zM0 5h2-2zM0 6h2V4H0z" />
            </g>
        </svg>
    );
});

Content.displayName = 'Content';
Content.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Content.defaultProps = {
    className: '',
    color: '#2E3542',
    height: 24,
    width: 24
};