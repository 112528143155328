/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Mail Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Mail = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M0 21h24V4H0v17zM1.75 5.3h20.24l-10 6.73L1.76 5.31zm20.98 1.07V19.7H1.27V6.55L12 13.59l10.73-7.22z"
                fill={color}
            />
        </svg>
    );
});

Mail.displayName = 'Mail';
Mail.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Mail.defaultProps = {
    className: '',
    color: '#2E3542',
    height: 24,
    width: 24
};