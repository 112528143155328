/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Download Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Download = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M1.63 14v6.43h18.74V14H22v8H0v-8h1.63zM12.3 0v13.93l6.57-6.65L20 8.4 11.5 17 3 8.41l1.13-1.13 6.58 6.65V0h1.59z"
                fill={color}
            />
        </svg>
    );
});

Download.displayName = 'Download';
Download.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Download.defaultProps = {
    className: '',
    color: '#2E3542',
    height: 23,
    width: 22
};