/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Star Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Star = React.forwardRef((props, ref) => {
    const {bgColor, className, height, starColor, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none">
                <path
                    d="M0 0h24v24h-48zm12 0L8.3 7.6 0 8.8l6 5.9L4.6 23l7.4-3.9 7.4 3.9-1.4-8.3 6-5.9-8.3-1.2L12 0z"
                    fill={bgColor}
                />
                <path
                    d="M12 0L8.3 7.6 0 8.8l6 5.9L4.6 23l7.4-3.9 7.4 3.9-1.4-8.3 6-5.9-8.3-1.2L12 0zM7.5 14.2L3 9.8l6.2-1L12 3.1l2.8 5.7 6.2 1-4.5 4.4 1.1 6.3-5.6-3-5.6 3 1.1-6.3z"
                    fill={starColor}
                    opacity="0.5"
                />
            </g>
        </svg>
    );
});

Star.displayName = 'Star';
Star.propTypes = {
    bgColor: PropTypes.string,
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    starColor: PropTypes.string,
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Star.defaultProps = {
    bgColor: '#ffffff',
    className: '',
    height: 24,
    starColor: '#D56B50',
    width: 24
};