/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * LocationSearch Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const LocationSearch = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox="0 0 43 43"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fillRule="evenodd"><path d="M19.5 42.5S4 26.84 4 16.972C4 8.427 10.94 1.5 19.5 1.5 28.06 1.5 35 8.427 35 16.972 35 26.84 19.5 42.5 19.5 42.5Z" fill="#D56B50" /><path d="M16 .125C7.232.125.125 7.22.125 15.972c0 2.557.995 5.592 2.823 9.012 1.304 2.442 3.003 5.022 5.002 7.678A99.349 99.349 0 0 0 13.3 39.15l.47.521.227.25.442.48.421.451.609.64.265.273.267.27.443-.451.495-.517.41-.437.432-.465.223-.243.46-.508c.08-.087.158-.175.238-.264a99.35 99.35 0 0 0 5.348-6.487c2-2.656 3.698-5.236 5.003-7.678 1.828-3.42 2.823-6.455 2.823-9.012C31.875 7.22 24.767.125 16 .125Zm0 .75c8.353 0 15.125 6.76 15.125 15.097 0 2.41-.96 5.337-2.734 8.659-1.285 2.404-2.963 4.953-4.94 7.58a98.61 98.61 0 0 1-5.309 6.438l-.466.517-.448.49-.216.234-.418.447-.412.435-.182.19-.394-.412-.407-.433-.211-.227-.438-.476-.457-.503a98.61 98.61 0 0 1-5.543-6.7c-1.978-2.627-3.656-5.176-4.94-7.58C1.833 21.309.874 18.383.874 15.97.875 7.635 7.646.876 16 .876Z" fill="#2E3542" fillRule="nonzero" /><g><path d="m42.913 38.533-7.594-7.489c3.629-4.484 3.379-11.049-.822-15.19a11.356 11.356 0 0 0-5.634-3.035c-3.673-.781-7.652.26-10.486 3.122-4.421 4.463-4.376 11.662.105 16.078a11.365 11.365 0 0 0 5.633 3.039c3.32.706 6.884-.097 9.633-2.37l7.562 7.462 1.603-1.617Z" fill="#D56B50" /><path d="M32.095 24.496a8.25 8.25 0 1 1-16.14-3.431 8.25 8.25 0 0 1 16.14 3.43" fill="#FAF6F4" /><path d="m39.697 36.827-7.594-7.488c3.63-4.485 3.379-11.05-.822-15.19a11.356 11.356 0 0 0-5.634-3.036c-3.673-.781-7.651.26-10.485 3.122-4.422 4.463-4.376 11.663.104 16.079a11.365 11.365 0 0 0 5.633 3.038c3.32.706 6.885-.096 9.633-2.37l7.563 7.462 1.602-1.617Z" stroke="#2E3542" strokeWidth=".706" /></g></g>
        </svg>
    );
});

LocationSearch.displayName = 'LocationSearch';
LocationSearch.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
LocationSearch.defaultProps = {
    className: '',
    height: 43,
    width: 43
};