/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Phone Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Phone = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M2.63 5.64c.03.36.15 1.11.65 2.45 2.07 4.26 7.65 10.97 11.2 12.73 2.43 1.2 3.82 1.49 4.25 1.52.46-.42 2.61-2.79 3.13-3.43l.33-.4-3.4-2.38a.91.91 0 0 0-.44.01l-3.04 1.73-.8-.58a29.83 29.83 0 0 1-7.07-7.63l-.47-.77.46-.97c.22-.45.87-1.8 1.27-2.54-.03-.13-.1-.35-.2-.52A40.04 40.04 0 0 0 6.42 1.8a86.23 86.23 0 0 0-3.8 3.83M18.83 24c-1.05 0-2.95-.64-5.04-1.67-3.92-1.94-9.74-8.97-11.95-13.5l-.07-.16c-.69-1.85-.8-2.87-.76-3.38l.01-.23.12-.19A64.2 64.2 0 0 1 5.63.31c.6-.42 1.2-.4 1.61-.03.71.63 2.55 3.56 2.63 3.69.04.07.76 1.3.32 2.1a45.5 45.5 0 0 0-1.31 2.6l-.06.12c.23.37 2.69 4.24 6.6 7.11l2.3-1.29c.4-.15.96-.27 1.64-.06l.22.1 3.84 2.7c.21.2.55.58.58 1.07.01.3-.1.6-.33.87l-.5.6c-.1.13-3.2 3.77-3.9 4.06a2 2 0 0 1-.45.05" fill={color} />
        </svg>
    );
});

Phone.displayName = 'Phone';
Phone.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Phone.defaultProps = {
    className: '',
    color: '#2E3542',
    height: 24,
    width: 24
};