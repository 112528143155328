/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Plus Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Plus = React.forwardRef((props, ref) => {
    const {className, color, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path
                d="M8.5 0a8.5 8.5 0 110 17 8.5 8.5 0 010-17zm0 1a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm-.52 3.07h1.04v3.7h3.7v1.04h-3.7v3.71H7.98V8.81h-3.7V7.78h3.7V4.07zm.54.5h-.04v3.7h-3.7v.04h3.7v3.71h.04V8.31h3.7v-.03h-3.7V4.57z"
                fill={color}
            />
        </svg>
    );
});

Plus.displayName = 'Plus';
Plus.propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Plus.defaultProps = {
    className: '',
    color: '#394251',
    height: 17,
    width: 17
};