/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Avatar Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Avatar = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <circle cx="37" cy="37" id="a" r="37" />
            </defs>
            <g fill="none" fillRule="evenodd" transform="translate(.5 .84)">
                <mask fill="#fff" id="b">
                    <use xlinkHref="#a" />
                </mask>
                <use fill="#FAEFED" xlinkHref="#a" />
                <g mask="url(#b)">
                    <circle cx="37" cy="26.73" fill="#DD8871" r="14.5" />
                    <path d="M64.39 58.92c-6.16-7.27-16.66-12.1-27.06-12.1-10.06 0-20.48 4.53-26.9 11.4-.17.19 11.98 14.11 24.8 14.28 14.24.19 29.31-13.4 29.16-13.58z" fill="#DD8871" />
                    <path d="M37 0a37 37 0 1 1 0 74 37 37 0 0 1 0-74zm0 4a33 33 0 1 0 0 66 33 33 0 0 0 0-66z" fill="#FAEFED" fillRule="nonzero" />
                </g>
            </g>
        </svg>
    );
});

Avatar.displayName = 'Avatar';
Avatar.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Avatar.defaultProps = {
    className: '',
    height: 75,
    width: 75
};