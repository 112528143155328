/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * Icon404 Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const Icon404 = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill="none" fillRule="evenodd">
                <path d="m12.12 97.35 61.76-5.4-7.67-87.67-61.76 5.4z" fill="#D56B50" />
                <path d="M62.56-.16-.7 5.37l7.8 89.16 63.26-5.54-7.8-89.15zM61.2 1.46l7.54 86.17L8.47 92.9.93 6.73 61.2 1.46z" fill="#2E3542" fillRule="nonzero" />
                <path d="M35.14 65.23a4.04 4.04 0 0 1 2.07-3.9 3.94 3.94 0 0 1 4.48.47 4.02 4.02 0 0 1 .49 5.66 4.09 4.09 0 0 1-2.74 1.39 4.04 4.04 0 0 1-2.92-.89 4.02 4.02 0 0 1-1.38-2.73zm-8.83-35.1a17.88 17.88 0 0 1 4.32-3.4 15 15 0 0 1 6.15-1.8c1.62-.14 3.13-.04 4.52.3 1.4.34 2.62.89 3.68 1.65a8.9 8.9 0 0 1 2.56 2.88 9.72 9.72 0 0 1 1.17 3.97c.13 1.48.03 2.79-.3 3.9-.34 1.13-.8 2.12-1.4 2.98a13.2 13.2 0 0 1-1.97 2.29l-2.06 1.86c-.65.58-1.2 1.15-1.65 1.71-.45.57-.7 1.16-.74 1.8l-.23 4.45-4.26.37-.86-4.8a3.8 3.8 0 0 1 .23-2.41c.33-.7.8-1.37 1.4-2.02.6-.64 1.27-1.27 2.01-1.87a15.5 15.5 0 0 0 2.05-2 10 10 0 0 0 1.53-2.38 5.9 5.9 0 0 0-.18-5.05 4.53 4.53 0 0 0-1.36-1.46 5.78 5.78 0 0 0-1.93-.84 9.1 9.1 0 0 0-5.2.51 12.15 12.15 0 0 0-3.3 2.08c-.34.3-.64.46-.9.48-.6.05-1.07-.17-1.4-.68l-1.88-2.52z" fill="#FFF4DC" />
            </g>
        </svg>
    );
});

Icon404.displayName = 'Icon404';
Icon404.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
Icon404.defaultProps = {
    className: '',
    height: 98,
    width: 74
};