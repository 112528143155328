/* eslint-disable max-len */
import React from 'react';

import PropTypes from 'prop-types';

/**
 * StepProgress Icon.
 *
 * @param {Object} props Component props.
 *
 * @returns {JSX} The svg icon.
 */
export const StepProgress = React.forwardRef((props, ref) => {
    const {className, height, width} = props;

    return (
        <svg
            ref={ref}
            className={className}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <circle cx="39" cy="83" id="a" r="30" />
            </defs>
            <g fill="none">
                <g transform="translate(9 5)">
                    <mask fill="#fff" id="b">
                        <use xlinkHref="#a" />
                    </mask>
                    <path d="M7 36h83v43H7z" fill="#D56B50" mask="url(#b)" transform="rotate(130 48.5 57.5)" />
                </g>
                <path
                    d="M71.61 28.67l-.9.42A29.15 29.15 0 0044.14 12v-1c11.94 0 22.6 7 27.46 17.67zM19.78 23.4l.81.59A29.02 29.02 0 0015 41.15c0 5.08 1.3 9.97 3.74 14.3l-.87.5A30.02 30.02 0 0114 41.14c0-6.47 2.05-12.64 5.78-17.76z"
                    fill="#2E3542"
                    fillRule="nonzero"
                />
                <path
                    d="M73.14 21.39l-.82.56A34.46 34.46 0 0043.9 7V6a35.46 35.46 0 0129.25 15.39zM9 48.09l.98-.18a34.55 34.55 0 0019.98 25.16l-.4.92A35.55 35.55 0 019 48.09zM64.84 19.5l.76.64L42.96 47l-.76-.64z"
                    fill="#2E3542"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
});

StepProgress.displayName = 'StepProgress';
StepProgress.propTypes = {
    className: PropTypes.string,
    height: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]),
    width: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ])
};
StepProgress.defaultProps = {
    className: '',
    height: 83,
    width: 87
};